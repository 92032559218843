// LogoutButton.js
import '../App.css'
import React from 'react';

const LogoutButton = () => {

  const logout = () => {
    localStorage.removeItem('access_token');
    window.location.reload();
  };

  return (
    <button onClick={logout} className='text-white py-3.5 hover:bg-slate-100 hover:text-black'>
      Log Out
    </button>
  );
};

export default LogoutButton;