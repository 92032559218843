import React from 'react';
import './fire_loader.scss'; // Ensure this path is correct

const FireLoader = () => {
  return (
    <div className="fire -translate-x-1">
      <div className="flames">
        <div className="flame"></div>
        <div className="flame"></div>
        <div className="flame"></div>
        <div className="flame"></div>
      </div>
      <div className="logs"></div>
    </div>
  );
};

// position: absolute;
// top:12%;
// left:50%;
// transform:translate(-50%,-25%);

export default FireLoader;