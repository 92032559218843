import React, { useEffect, useState, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function Profile() {
  const [roasts, setRoasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const loader = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    setLoading(true);

    fetch('/api/profile', {
      method: 'GET', // Assuming /api/profile should be accessed with GET
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Corrected template literal syntax
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setRoasts(data); // Fixed to use 'data'
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);        
    });

  }, []);
  const handleDelete = (roastId) => {
    const isconfirmed = window.confirm('Are you sure you want to delete this roast?');
    if (!isconfirmed) {
      return;
    }

    const token = localStorage.getItem('access_token');
  
    fetch(`/api/delete_roast/${roastId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    })
    .then(data => {
      console.log(data.message);
      setRoasts(prevRoasts => prevRoasts.filter(roast => roast.id !== roastId));
    })
    .catch(error => {
      console.error('Error deleting roast:', error.error || 'An unknown error occurred');
    });
  };

  return (
    <div className="bg-slate-800 rounded-md">
      <h2 className="text-4xl font-bold p-4 mb-4 text-white text-center">Recent Roasts</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-items-center">
        {roasts.map(roast => (
          <div key={roast.id} className="flex flex-col bg-white max-w-md p-4 rounded-md">
            {roast.image_url && <img src={roast.image_url} alt="Roast" className="w-full rounded-md mb-4" />}
            <p className='text-black text-xl'>{roast.caption}</p>
            {roast.city && roast.country && <p className='text-black'>{roast.city}, {roast.country}</p>}
            <IconButton
              onClick={() => handleDelete(roast.id)}
              className="right-0 m-2"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        {loading && 
        <div ref={loader} role="status">
        <svg className="animate-spin -ml-1 mr-1 h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          <span className="sr-only">Loading...</span>
        </div>
        }
      </div>
    </div>
  );
}

export default Profile;
