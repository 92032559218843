import '../App.css'
import { Outlet, Link } from "react-router-dom";
import LogoutButton from '../components/LogoutButton';

const Layout = () => {
  const isLoggedIn = localStorage.getItem('access_token');
  return (
    <div>
        <header className='header bg-slate-800'>
            <nav>
                <ul className='navbar'>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/roasts">Recent roasts</Link></li>
                    <li><Link to="/about">About</Link></li>
                    {isLoggedIn ? (
                      <>
                      <li><Link to="/profile">Profile</Link></li>
                      <li><LogoutButton/></li>
                      </>
                    ) : (
                      <li><Link to="/login">Log in</Link></li>
                    )}
                </ul>
            </nav>
        </header> 
      <Outlet />
    </div>
  )
};

export default Layout;