import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import PhotoUpload from './Pages/Home';
import Roasts from './Pages/Roasts';
import Layout from './Pages/Layout';
import NotFound from './Pages/notfound';     
import Login from './Pages/Login';
import Register from './Pages/Register';
import Profile from './Pages/Profile';
import RoastPage from './Pages/RoastPage';
import Toy from './Pages/ToyRobot'


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<PhotoUpload/>}/>
          <Route path='register' element={<Register/>}/>
          <Route path='login' element={<Login/>}/>
          <Route path='profile' element={<Profile/>}/>
          <Route path='roasts' element={<Roasts/>}/>
          <Route path='roast/:roastID' element={<RoastPage/>}/>
          <Route path='toy-robot' element={<Toy/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);