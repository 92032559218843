import React, { useEffect, useState } from 'react';

function TypingEffect({ text }) {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (typeof text !== 'string') {
      setDisplayedText('');
      return;
    }
    setDisplayedText('')
    console.log(text)
    // const words = text.split(' ');
    const words = (text.split(' '));

    let wordIndex = 0;
    const intervalId = setInterval(() => {
      if (wordIndex < words.length) {
        const currentWord = words[wordIndex];
        if (currentWord !== undefined) {
          setDisplayedText((prev) => `${prev}${currentWord} `);
        }
        wordIndex++;
      } else {
        clearInterval(intervalId); // Stop the interval when all words are displayed
      }
    }, 75); // Adjust the speed of typing here

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [text]); // Rerun the effect when the text changes

  return (
    <div>
      {displayedText}
    </div>
  );
}

export default TypingEffect;
