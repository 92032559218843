import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function RoastPage() {
  const [roast, setRoast] = useState(null);
  const { roastID } = useParams(); // Make sure the parameter name matches the one specified in your route

  useEffect(() => {
    // Corrected to use template literals for embedding expressions
    fetch(`/api/get_roast/${roastID}`) // Assuming the backend is hosted at the same origin
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setRoast(data);
        console.log(data);
      })
      .catch(error => console.error('Error fetching roast:', error));
    }, [roastID]); // Dependency array to refetch when roastID changes

  // Display a loading message or similar if the roast hasn't been fetched yet
  if (!roast) return <div className='text-white justify-center text-3xl'>Loading...</div>;

  return (
    <div className="bg-slate-800 rounded-md">
      <h2 className="text-4xl font-bold p-4 mb-4 text-white text-center">Roast {roast.id}</h2>
      <div className="flex flex-col bg-white max-w-md p-4 rounded-md mx-auto">
        {roast.image_url && <img src={roast.image_url} alt="Roast" className="w-full rounded-md mb-4" />}
        <p className='text-black text-xl'>{roast.caption}</p>
        {roast.city && roast.country && <p className='text-black'>{roast.city}, {roast.country}</p>}
      </div>
    </div>
  );
}
export default RoastPage;