import React, { useEffect, useState, useRef } from 'react';

function Roasts() {
  const [roasts, setRoasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const loader = useRef(null);
  const [hasMore, setHasMore] = useState(true)
  // Fetch roasts function
  const fetchRoasts = async () => {
    setLoading(true);
    fetch(`/api/roasts?page=${page}`)
      .then(response => response.json())
      .then(data => {
        if (data.length === 0) { 
          setHasMore(false);
        } else {
        setRoasts(prevRoasts => [...prevRoasts, ...data]);
        setPage(prevPage => prevPage + 1);
        setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
        setHasMore(false);      
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !loading && hasMore) {
          fetchRoasts();
        }
      },
      { threshold: 1 }
    );
  
    if (loader.current) {
      observer.observe(loader.current);
    }
  
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loading]);

  return (
    <div className="bg-slate-800 rounded-md">
      <h2 className="text-4xl font-bold p-4 mb-4 text-white text-center">Recent Roasts</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-items-center">
        {roasts.map(roast => (
          <div key={roast.id} className="flex flex-col bg-white max-w-md p-4 rounded-md">
            {roast.image_url && <img src={roast.image_url} alt="Roast" className="w-full rounded-md mb-4" />}
            <p className='text-black text-xl'>{roast.caption}</p>
            {roast.city && roast.country && <p className='text-black'>{roast.city}, {roast.country}</p>}
          </div>
        ))}
        {loading && <div>Loading...</div>}
        <div ref={loader} role="status">
        <svg className="animate-spin -ml-1 mr-1 h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default Roasts;
