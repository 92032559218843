import React, { useState } from 'react';
import '../App.css'
import { Link } from 'react-router-dom';
// import AdsenseComponent from '../Adsensecomponent';
import FireLoader from '../components/fire_loader';
import TypingEffect from '../components/typingeffect';
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

function PhotoUpload() {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [response, setResponse] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [postID, setPostID] = useState('');
  const [showAD , setShowAD] = useState(false);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setImage(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
    }
  };

  const HandleToggleChange = () => {
    setIsPublic(!isPublic);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) return;

    const formData = new FormData();
    formData.append('image', image);
    formData.append('Public', isPublic ? 'true' : 'false');
    setLoading(true);
    // setShowAD(false);
 
    const storedToken = localStorage.getItem('access_token');
    const defaultToken = 'default-token'; // Replace with your actual default token
    const token = storedToken || defaultToken;
    console.log('Token retrieved:', token);
    // const apiEndpoint = accessToken ? '/api/roast_with_auth' : '/api/roast';
    console.log('Sending fetch request');
    try {
      const res = await fetch('/api/create_roast', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await res.json();
      console.log('Data Received: ', data);
      setResponse(data.caption);
      setPostID(data.id)
      console.log(postID)
      setLoading(false);
      // setShowAD(false);
    } catch (error) {
      console.error('Error creating Roast:', error);
      console.error('Error name:', error.name);
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
      setResponse(error.message); // Set only the error message, not the entire error object
    }
      // Handle errors here

  };

  const handleRoastAgain = async () => {
    setLoading(true);

    try {
      const result = await fetch('/api/roast_again', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postID })  // Ensure you're sending a JSON object
      });
      console.log(postID)
      if (!result.ok) throw new Error('Network response was not ok.');

      const data = await result.json();
      setResponse(data.caption); // Assuming the API response contains a "message" field with the new roast
    } catch (error) {
      console.error('Error fetching new roast:', error);
      setResponse('Error fetching new roast.');
    }

    setLoading(false);
  };


  const handleCompliment = async () => {
    setLoading(true);

    try {
      const result = await fetch('/api/compliment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postID })  // Ensure you're sending a JSON object
      });
      console.log(postID)
      if (!result.ok) throw new Error('Network response was not ok.');

      const data = await result.json();
      setResponse(data.caption); // Assuming the API response contains a "message" field with the new roast
    } catch (error) {
      console.error('Error fetching new compliment:', error);
      setResponse('Error fetching new compliment.');
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center bg-slate-800 pt-32 min-h-screen"> {/* Centers content and adds margin on the Y-axis */}
      <link href="./output.css" rel="stylesheet"></link>
      <div className="w-32 flex-none translate-x-12"> {/* Restricts FireLoader width and prevents it from growing */}
        <FireLoader/>
      </div>
      <div className="flex items-center justify-center text-white space-x-4"> {/* Ensures flexbox layout with centered alignment and space between items */}
      <h1 className="font-bold py-2 text-3xl text-center mb-2">🔥Upload a photo to get roasted🔥</h1>
      </div>
      <div className='text-center mb-2 text-white'>
      <h2 className = "font-bold mb-2 text-base">Takes ~20s</h2>
      <h3 className='font-thin text-base m-3'>The roast is AI generated, I claim no responsibility for its output</h3>
      </div>
      <label class="inline-flex items-center cursor-pointer ml-12 mb-2">
          <input type="checkbox" value="" class="sr-only peer" checked={isPublic} onChange={HandleToggleChange}/>
          <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="ms-3 text-sm font-medium text-white dark:text-gray-300">{isPublic ? 'Public\n(shown in recent roasts)' : 'Private'}</span>
        </label>
      <form onSubmit={handleSubmit} className="flex flex-row justify-center items-center px-4"> {/* Ensures form content is centered */}
        <label id= "choosefile" className="cursor-pointer bg-blue-500 mr-3 mb-2 hover:bg-blue-700 text-white font-medium py-2 px-5 rounded-md"> {/* Styles the label which is acting as the 'Choose file' button */}
          Choose file
          <input type="file" className="hidden" onChange={handleImageChange} accept="image/*" />
        </label>
        <button type="submit" className="bg-orange-400 rounded-md font-bold px-5 py-2 mb-2 hover:bg-red-500 text-slate-800">{loading ? (
            <svg className="animate-spin -ml-1 mr-1 h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : 'Roast Me!'}</button>
      </form>
      <Link to="/roasts" className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded justify-center mb-3">
        Recent Roasts
      </Link>
      {/* {showAD && (
        <div>
        <AdsenseComponent/>
        </div>
      )} */}
      {previewUrl && (
        <div className="w-full max-w-screen-lg mx-auto"> {/* This container ensures everything is centered and doesn't exceed a max width */}
          <div className="w-full"> {/* Container for the image */}
            <img
              src={previewUrl}
              alt="Preview"
              className="rounded-lg mb-4 w-full max-h-screen  object-cover  lg:object-contain" />
          </div>
          {image && (
            <div className="w-full"> {/* This ensures the response box matches the width of the image above */}
              <p className="text-center text-black rounded-lg bg-slate-50 mb-4 border-slate-600 p-4">
                {response && (
                  <TypingEffect text={response} />
                )}
              </p>
            </div>
          )}
        </div>
      )}
      {response && (
        <>
        <div>
          <button 
            onClick={handleRoastAgain} // Call handleRoastAgain when the button is clicked
            type="button" // Changed to "button" since it's not submitting a form here
            className="bg-orange-400 rounded-md font-bold px-5 py-2 mb-3 hover:bg-red-500 text-slate-800"
            disabled={loading} // Disable button while loading
          >
            {loading ? (
              <svg className="animate-spin -ml-1 mr-1 h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : 'Roast Me Again!'}
          </button>
        </div>
        <div>
          <button 
            onClick={handleCompliment} // Call handleRoastAgain when the button is clicked
            type="button" // Changed to "button" since it's not submitting a form here
            className="bg-green-400 rounded-md font-bold px-5 py-2 mb-7 hover:bg-green-500 text-slate-800"
            disabled={loading} // Disable button while loading
          >
            {loading ? (
              <svg className="animate-spin -ml-1 mr-1 h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : 'Compliment me!'}
          </button>
        <div className='rounded-md'> 
          <WhatsappShareButton url={`https://roastmeai.com/roast/${postID}`} title={response} className='mr-4'>
            <button>
              <WhatsappIcon className='rounded-md'/>
            </button>
          </WhatsappShareButton>
          <FacebookMessengerShareButton url={`https://roastmeai.com/roast/${postID}`} quote={response}>
            <button>
              <FacebookMessengerIcon className='rounded-md'/>
            </button>
          </FacebookMessengerShareButton>
        </div>
        </div>
        </>
      )}
    </div>
  );
}

export default PhotoUpload;